<template>
    <div
        class="w-full dF fC f1 px-4 pb-4 hide-scrollbar"
        style="overflow: scroll; background-color: #f7f5f9"
    >
        <instructionsModal />
        <domainModal />
        <deviceModal />
        <connectModal />
        <renameModal />
        <restartModal />
        <a-modal
            :visible="screenshotModal.visible"
            @cancel="closeScreenshot"
            @ok="closeScreenshot"
            :footer="null"
            :bodyStyle="{ padding: '30px' }"
            centered
        >
            <img
                :src="screenshotModal.url"
                style="object-fit: contain"
                class="w-full"
            />
        </a-modal>

        <div>
            <h4 class="mb-3 mt-3">{{ sidebarSelection }}</h4>
        </div>

        <!-- DOMAINS TAB -->
        <template v-if="sidebarSelection === 'Domains'">
            <a-card
                v-if="domains && domains.length"
                class="mb-5 hide-scrollbar"
                style="overflow-x: scroll"
                :bodyStyle="{ padding: 0 }"
            >
                <a-table
                    class="white-table domains-table"
                    :columns="domainColumns"
                    :dataSource="domains"
                    :pagination="false"
					:rowKey="(e) => e.id"
                >
                    <div
                        slot="url"
                        slot-scope="obj"
                        class="dF"
                        style="font-weight: bold"
                    >
                        {{ obj.url }}
                    </div>
                    <div slot="status" slot-scope="obj" class="dF">
                        <div
                            v-if="obj.linked"
                            class="status-block dF jC"
                            style="background-color: #00c48c"
                        >
                            CONNECTED
                        </div>
                        <div
                            v-else
                            class="status-block dF jC"
                            style="background-color: #fd6c83"
                        >
                            NOT CONNECTED
                        </div>
                    </div>
                    <div
                        slot="datecreated"
                        slot-scope="obj"
                        class="dF"
                        style="color: #9ea0a5"
                    >
                        {{ convertDate(obj.createdAt) }}
                    </div>
                    <div slot="project" slot-scope="obj" class="dF">
                        <a-popover
                            trigger="click"
                            overlayClassName="popoverStyle"
                        >
                            <template slot="content">
                                <a-menu slot="overlay" style="max-height: 300px; overflow: scroll;">
                                    <a-menu-item
                                        class="popoverContent"
                                        @click="updateDomainProj(obj, proj)"
                                        v-for="(
                                            proj, projI
                                        ) in filteredProjects"
                                        :key="proj + projI"
                                    >
                                        <div :title="proj.name" class="dF aC" style="overflow: hidden;">
                                            {{ proj.name }}
                                        </div>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <div class="dF aC" style="cursor: pointer">
                                <div class="mr-3">{{ obj.project && obj.project.name }}</div>
                                <a-icon type="caret-down" />
                            </div>
                        </a-popover>
                    </div>
                    <div slot="actions" slot-scope="obj" class="dF jE aC">
                        <div v-if="!obj.linked">
                            <svg
                                @click="connectDomain(obj)"
                                class="rotate-button mr-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 21.374 21.374"
                            >
                                <path
                                    id="Icon_awesome-redo-alt"
                                    data-name="Icon awesome-redo-alt"
                                    class="cls-1"
                                    d="M11.269.562a10.651,10.651,0,0,1,7.363,2.96l1.539-1.539a1.034,1.034,0,0,1,1.766.731V8.492A1.034,1.034,0,0,1,20.9,9.526H15.125a1.034,1.034,0,0,1-.731-1.766l1.8-1.8A7.239,7.239,0,1,0,16.017,16.7a.516.516,0,0,1,.705.024l1.709,1.709a.518.518,0,0,1-.021.751A10.687,10.687,0,1,1,11.269.562Z"
                                    transform="translate(-0.563 -0.562)"
                                />
                            </svg>
                        </div>
                        <div @click="deleteDomain(obj)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="delete-button feather feather-trash-2"
                            >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                ></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                        </div>
                    </div>
                </a-table>
            </a-card>

            <template v-else>
                <EmptyApp
                    :text="'You don\'t have any domains'"
                    :img="require('@/assets/emptyIcons/domain.png')"
                />
            </template>
        </template>

        <!-- DEVICES TAB -->
        <template v-else-if="sidebarSelection === 'Devices'">
            <a-card
                v-if="devices && devices.length"
                class="mb-5 hide-scrollbar"
                style="overflow-x: scroll"
                :bodyStyle="{ padding: 0 }"
            >
                <a-table
                    class="white-table devices-table"
                    :columns="deviceColumns"
                    :dataSource="devices"
                    :pagination="false"
					:rowKey="(e) => e.id"
                >
                    <div slot="name" slot-scope="obj" class="dF aC">
                        <div class="mr-3" style="font-weight: bold">
                            {{ obj.name }}
                        </div>
                        <div class="showEdit">
                            <svg
                                @click="renameDevice(obj)"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-edit-2 edit-button"
                            >
                                <path
                                    d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                                ></path>
                            </svg>
                        </div>
                    </div>
                    <div slot="status" slot-scope="obj" class="dF">
                        <div
                            v-if="obj.online"
                            class="status-block dF jC"
                            style="background-color: #00c48c"
                        >
                            CONNECTED
                        </div>
                        <div
                            v-else
                            class="status-block dF jC"
                            style="background-color: #fd6c83"
                        >
                            NOT CONNECTED
                        </div>
                    </div>
                    <div
                        slot="datecreated"
                        slot-scope="obj"
                        class="dF"
                        style="color: #9ea0a5"
                    >
                        {{ convertDate(obj.createdAt) }}
                    </div>
                    <div slot="linkedId" slot-scope="obj" class="dF">
                        <div>
                            {{ obj.type == 'touch' && obj.linkedId != '' ? linkedIdName(obj.linkedId) : 'Siteplan Table' }}
                        </div>
                    </div>
                    <div slot="actions" slot-scope="obj" class="dF jE aC">
                        <template v-if="obj.online">
                            <a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    Send a command to this device
                                </template>
                                <div>
                                    <a-popover
                                        v-model="codeModal.visible"
                                        title="Send a Command to Device"
                                        trigger="click"
                                    >
                                        <div slot="content">
                                            <div class="dF aC">
                                                <a-input
                                                    class="f1 mr-2"
                                                    v-model="codeModal.command"
                                                ></a-input>
                                                <a-button
                                                    @click="codeDevice(obj)"
                                                    :loading="codeModal.loading"
                                                    type="primary"
                                                    >SEND</a-button
                                                >
                                            </div>
                                        </div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="22"
                                            height="22"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="
                                                code-button
                                                feather feather-code
                                                mr-3
                                            "
                                        >
                                            <polyline
                                                points="16 18 22 12 16 6"
                                            ></polyline>
                                            <polyline
                                                points="8 6 2 12 8 18"
                                            ></polyline>
                                        </svg>
                                    </a-popover>
                                </div>
                            </a-tooltip>
                            <a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    Capture a screenshot from this device
                                </template>
                                <div @click="screenshotDevice(obj)">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="
                                            screenshot-button
                                            feather feather-camera
                                            mr-3
                                        "
                                    >
                                        <path
                                            d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"
                                        ></path>
                                        <circle cx="12" cy="13" r="4"></circle>
                                    </svg>
                                </div>
                            </a-tooltip>
                            <a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    Restart this device
                                </template>
                                <div>
                                    <svg
                                        @click="restartDevice(obj)"
                                        class="rotate-button mr-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="21"
                                        viewBox="0 0 22.146 22.999"
                                    >
                                        <g
                                            id="Group_5962"
                                            data-name="Group 5962"
                                            transform="translate(-331.49 -325)"
                                        >
                                            <path
                                                id="Path_7687"
                                                data-name="Path 7687"
                                                class="cls-1"
                                                d="M351.993,340.358a9.8,9.8,0,1,1-2.146-10.852l-1.187,1.187c-.206.205-.137.4.152.424l3.72.357a.42.42,0,0,0,.475-.475l-.357-3.72c-.028-.289-.219-.358-.424-.152l-1.174,1.174a11.48,11.48,0,1,0,2.516,12.722.853.853,0,0,0-.361-1.071h0A.856.856,0,0,0,351.993,340.358Z"
                                            />
                                            <path
                                                id="Path_7688"
                                                data-name="Path 7688"
                                                class="cls-1"
                                                d="M463.405,440.086l-6.284,3.628a.623.623,0,0,1-.934-.539v-7.256a.623.623,0,0,1,.934-.539l6.284,3.628A.623.623,0,0,1,463.405,440.086Z"
                                                transform="translate(-116.503 -103.047)"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            </a-tooltip>
                            <a-tooltip overlayClassName="change-tooltip-color">
                                <template slot="title">
                                    Reload this device
                                </template>
                                <div @click="reloadDevice(obj)">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="
                                            reload-button
                                            feather feather-refresh-cw
                                            mr-3
                                        "
                                    >
                                        <polyline
                                            points="23 4 23 10 17 10"
                                        ></polyline>
                                        <polyline
                                            points="1 20 1 14 7 14"
                                        ></polyline>
                                        <path
                                            d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"
                                        ></path>
                                    </svg>
                                </div>
                            </a-tooltip>
                        </template>

                        <div @click="deleteDevice(obj)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="delete-button feather feather-trash-2"
                            >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                ></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                        </div>
                    </div>
                </a-table>
            </a-card>

            <template v-else>
                <EmptyApp
                    :text="'You don\'t have any devices added'"
                    :img="require('@/assets/emptyIcons/devices.png')"
                />
            </template>
        </template>
    </div>
</template>

<script>
import { getBase64, arrToObj, formatDate } from "bh-mod";
import BHLoading from "bh-mod/components/common/Loading";
import EmptyApp from "bh-mod/components/common/EmptyApp";
import domainModal from "@/components/common/domainModal";
import connectModal from "@/components/common/connectModal";
import deviceModal from "@/components/common/deviceModal";
import renameModal from "@/components/common/renameModal";
import restartModal from "@/components/common/restartModal";
import instructionsModal from "@/components/common/instructionsModal";

export default {
    components: {
        BHLoading,
        EmptyApp,
        domainModal,
        connectModal,
        deviceModal,
        renameModal,
        restartModal,
        instructionsModal,
    },
    data() {
        return {
            domainColumns: [
                {
                    title: "Domain Name",
                    scopedSlots: { customRender: "url" },
                    key: "url",
                },
                {
                    title: "Status",
                    scopedSlots: { customRender: "status" },
                    key: "status",
                },
                {
                    title: "Date Added",
                    scopedSlots: { customRender: "datecreated" },
                    key: "datecreated",
                },
                {
                    title: "Web Project",
                    scopedSlots: { customRender: "project" },
                    key: "project",
                },
                {
                    title: "",
                    scopedSlots: { customRender: "actions" },
                    key: "actions",
                },
            ],
            deviceColumns: [
                {
                    title: "Device Name",
                    scopedSlots: { customRender: "name" },
                    key: "name",
                },
                {
                    title: "Status",
                    scopedSlots: { customRender: "status" },
                    key: "status",
                },
                {
                    title: "Date Added",
                    scopedSlots: { customRender: "datecreated" },
                    key: "datecreated",
                },
                {
                    title: "Digital Signage",
                    scopedSlots: { customRender: "linkedId" },
                    key: "linkedId",
                },
                {
                    title: "",
                    scopedSlots: { customRender: "actions" },
                    key: "actions",
                },
            ],
            codeModal: {
                visible: false,
                command: "",
            },
            screenshotModal: {
                visible: false,
                url: "",
            },
        };
    },
    watch: {},
    computed: {
		dateFormat(){
			return this.$store.state.appData.allSettings.user && this.$store.state.appData.allSettings.user.options && this.$store.state.appData.allSettings.user.options.regional && this.$store.state.appData.allSettings.user.options.regional.dateFormat ? this.$store.state.appData.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        sidebarSelection() {
            return this.$store.state.sidebarSelection;
        },
        domains() {
            return this.$store.state.appData.domains;
        },
        devices() {
            return this.$store.state.appData.devices;
        },
        filteredProjects() {
            let projInDomains = this.domains.map((x) => (x = x.project.id));
            return this.$store.state.appData.projects.filter(
                (x) => !projInDomains.includes(x.id)
            );
        },
        projects() {
            return this.$store.state.appData.projects;
        },
        touchscreens() {
            return this.$store.state.appData.touchscreens;
        },
    },
    methods: {
        closeScreenshot() {
            this.screenshotModal = {
                visible: false,
                url: "",
            };
        },
        reloadDevice(device) {
            this.$api
                .put(`/devices/:instance/${device.id}/updatedata`)
                .then(({ data }) => {
                    this.$message.success("Device was successfully reloaded!");
                })
                .catch((err) => {
                    console.error("Error", err);
                });
        },
        codeDevice(device) {
            this.codeModal.loading = true;
            let sendObj = {
                command: this.codeModal.command,
            };
            this.$api
                .put(`/devices/:instance/${device.id}/command`, sendObj)
                .then(({ data }) => {
                    this.$message.success("Command was successfully sent!");
                    this.codeModal = {
                        visible: false,
                        command: "",
                        loading: false,
                    };
                })
                .catch((err) => {
                    console.error("Error", err);
                });
        },
        async screenshotDevice(device) {
            let { data } = await this.$api.put(
                `/devices/:instance/${device.id}/screenshot`
            );
            this.$message.success(
                "Screenshot was successfully taken! Generating image..."
            );
            if (data.success == 1) {
                setTimeout(() => {
                    this.$api
                        .get(`/devices/:instance/${device.id}`)
                        .then(({ data }) => {
                            this.screenshotModal = {
                                visible: true,
                                url: data.image,
                            };
                        })
                        .catch((err) => {
                            console.error("Error", err);
                        });
                }, 3000);
            } else {
                this.$message.error("Something went wrong.");
            }
        },
        updateDomainProj(domain, project) {
            let sendObj = JSON.parse(JSON.stringify(domain));
            sendObj.project = project.id;
            this.$api
                .put(`/domains/:instance/${domain.id}`, sendObj)
                .then(({ data }) => {
                    this.$store.commit("UPDATE_DOMAINS", data);
                })
                .catch((err) => {
                    console.error("Error", err);
                });
        },
        linkedIdName(data) {
            let index = this.touchscreens.findIndex((x) => x.id == data);
            return this.touchscreens[index] && this.touchscreens[index].name;
        },
        restartDevice(obj) {
            this.$store.commit("RESTART_DEVICE", obj);
        },
        renameDevice(obj) {
            this.$store.commit("RENAME_DEVICE", obj);
        },
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        deleteDomain(obj) {
            let self = this;
            this.$confirm({
                title: "Delete Domain",
                content: (h) => <div>Do you want to delete this Domain?</div>,
                okText: "Delete",
                okType: "danger",
                cancelText: "Cancel",
                centered: true,
                onOk() {
                    self.$api
                        .delete(`/domains/:instance/${obj.id}`)
                        .then(({ data }) => {
                            let project = data.project;
                            project.domain = null;
                            self.$store.commit("DELETE_DOMAIN", data);
                        })
                        .catch((err) => {
                            console.error("Error", err);
                        });
                },
            });
        },
        connectDomain(obj) {
            this.$store.commit("CONNECT_DOMAIN", obj);
        },
        deleteDevice(obj) {
            let self = this;
            this.$confirm({
                title: "Delete Device",
                content: (h) => (
                    <div>
                        Do you want to delete this Device? Device will be
                        removed and reset to factory settings
                    </div>
                ),
                okText: "Delete",
                okType: "danger",
                cancelText: "Cancel",
                centered: true,
                onOk() {
                    self.$api
                        .delete(`/devices/:instance/${obj.id}`)
                        .then(({ data }) => {
                            self.$store.commit("DELETE_DEVICE", data);
                        })
                        .catch((err) => {
                            console.error("Error", err);
                        });
                },
            });
        },
    },
    mounted() {},
    created() {},
};
</script>

<style lang="scss" scoped>
.popoverContent {
    height: 35px;
    width: 150px;
    line-height: 35px;
    padding-left: 10px;
}
.popoverContent:hover {
    background-color: var(--off-white-light);
    cursor: pointer;
}
.delete-button {
    color: #9ea0a5;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}
.delete-button:hover {
    color: var(--danger);
    transition: color 0.3s ease-in-out;
}
.rotate-button {
    fill: #9ea0a5;
    cursor: pointer;
    transition: fill 0.3s ease-in-out;
}
.rotate-button:hover {
    fill: var(--orange);
    transition: fill 0.3s ease-in-out;
}
.code-button {
    stroke: #9ea0a5;
    cursor: pointer;
    transition: stroke 0.3s ease-in-out;
}
.code-button:hover {
    stroke: var(--orange);
    transition: stroke 0.3s ease-in-out;
}
.screenshot-button {
    stroke: #9ea0a5;
    cursor: pointer;
    transition: stroke 0.3s ease-in-out;
}
.screenshot-button:hover {
    stroke: var(--orange);
    transition: stroke 0.3s ease-in-out;
}
.reload-button {
    stroke: #9ea0a5;
    cursor: pointer;
    transition: stroke 0.3s ease-in-out;
}
.reload-button:hover {
    stroke: var(--orange);
    transition: stroke 0.3s ease-in-out;
}
.status-block {
    color: white;
    text-align: center;
    width: 130px;
    padding: 5px 5px;
    border-radius: 4px;
}
.edit-button {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    transition: color 0.1s ease-in;
}
.edit-button:hover {
    color: orange;
    transition: color 0.1s ease-in;
}
.showEdit {
    opacity: 0;
}
</style>

<style lang="scss">
.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner {
    background: #3f3356;
}
.devices-table .ant-table-row:hover {
    .showEdit {
        opacity: 1;
    }
}
.popoverStyle .ant-popover-inner-content {
    padding: 0;
    background-color: white;
    border-radius: 20px;
}
.popoverStyle .ant-menu-item-selected {
    color: inherit !important;
}
.popoverStyle .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: inherit !important;
}
</style>
