<template>
    <a-modal
        :visible="instructionsVisible"
        @cancel="onClose"
        @ok="onClose"
        :footer="null"
        :bodyStyle="{ padding: '30px' }"
        :zIndex="1001"
        centered
    >
        <h5 class="mb-5">Set up your existing domain to connect to Bildhive</h5>

        <div class="w-full">
            <div class="mb-3" style="color: black">
                Bildhive automatically assigns an SSL certificate to your
                domain.
            </div>
            <div class="mb-3" style="color: black">
                If you've purchased your domain through third party, then you
                need to edit your DNS settings and point your A Record to
                Bildhive's IP address, which is <span style="color: var(--orange);">{{$tld == 'dev' ? '159.89.114.168' : '157.230.71.223'}}</span>, and your CNAME
                Record to point to your domain name example.com.
            </div>
            <ol>
                <li>
                    Log in to the account you have with your domain provider.
                </li>
                <li>
                    Find your DNS settings. Look for your account admin panel,
                    or your domain management area, or DNS configuration, or
                    similar.
                </li>
                <li>
                    Edit your
                    <span style="color: var(--orange)">A Record</span> to point
                    to Bildhive's IP address
                    <span style="color: var(--orange)">{{$tld == 'dev' ? '159.89.114.168' : '157.230.71.223'}}</span>.
                    The admin panel might feature drop-downs or editable fields,
                    or you might have to check boxes or edit a table. However
                    you interact with the admin, you need to:
                </li>
                <ul>
                    <li>
                        enter or choose the
                        <span style="color: var(--orange)">@</span> symbol, or
                        <span style="color: var(--orange)">A Record</span>
                    </li>
                    <li>
                        enter Bildhive's IP address
                        <span style="color: var(--orange)">{{$tld == 'dev' ? '159.89.114.168' : '157.230.71.223'}}</span>
                        as the destination for the
                        <span style="color: var(--orange)">A Record</span>.
                    </li>
                </ul>
                <li>
                    Save the
                    <span style="color: var(--orange)">A Record</span> (click
                    Save, Save Zone File, Add Record, or similar).
                </li>
                <li>
                    Find the
                    <span style="color: var(--orange)">CNAME Record</span> in
                    your DNS settings.
                </li>
                <li>
                    Change your
                    <span style="color: var(--orange)">www CNAME Record</span>
                    to point to your domain name
                    <span style="color: var(--orange)">www.example.com</span>.
                    This redirects your customers to your website when they
                    enter www before your domain name. Note: Often, you need to
                    provide just the prefix without the dot, but for some domain
                    providers you need the whole subdomain. Use the existing
                    entries as a guide, or follow your provider's instructions.
                    Note: CNAME records must always point to a domain name,
                    never to an IP address.
                </li>
                <li>
                    Save the
                    <span style="color: var(--orange)">CNAME Record</span>
                    (usually by clicking Save or Add Record or similar).
                </li>
            </ol>
            <div class="mt-3" style="color: black">
                Now you need to verify your connection in Bildhive.<br /><br />
                Verify your connection in Bildhive<br /><br />
                Click
                <span style="color: var(--orange)">Verify Connection</span> in
                your Bildhive domain manager to confirm that your custom domain
                has been successfully connected.
            </div>
        </div>

        <div class="mt-5 dF jE">
            <a-button size="large" @click="onClose" style="width: 125px"
                >CLOSE</a-button
            >
        </div>
    </a-modal>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    watch: {},
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        instructionsVisible() {
            return this.$store.state.instructionsVisible;
        },
    },
    methods: {
        onClose() {
            this.$store.commit("CLOSE_INSTRUCTIONS");
        },
    },
    created() {},
    mounted() {
		console.log('TLD', this.$tld)
	},
};
</script>

<style lang="scss">
.domainForm .ant-form-item-with-help {
    margin-bottom: 0;
}
.domainForm .ant-form-item:not(.ant-form-item-with-help) {
    margin-bottom: 19px;
}
</style>

<style lang="scss" scoped>
</style>
