<template>
    <a-modal
        :visible="deviceModal.visible"
        @cancel="onClose"
        @ok="onClose"
        :footer="null"
        :bodyStyle="{ padding: '30px' }"
        centered
    >
        <BHLoading :show="loading" />
        <h5 class="mb-4">Add a device to your digital signage</h5>

        <div>
            <a-form-model
                class="domainForm"
                ref="ruleForm"
                :model="device"
                :rules="rules"
            >
                <a-form-model-item label="Device ID" prop="code">
                    <a-input v-model="device.code" size="large" />
                </a-form-model-item>
                <div class="mb-4" style="font-size: 12px; color: #9ea0a5">
                    Enter the device ID you want to connect
                </div>

                <a-form-model-item label="Device Name" prop="name">
                    <a-input v-model="device.name" size="large" />
                </a-form-model-item>

				<a-form-model-item label="Device Type" prop="type">
                    <a-select
                        v-model="device.type"
                        size="large"
                    >
						<a-select-option value="touch">
							Touchscreen
						</a-select-option>
						<a-select-option value="siteplan">
							Siteplan Table
						</a-select-option>
                    </a-select>
                </a-form-model-item>

				<template v-if="device.type == 'touch'">
					<a-form-model-item label="Assign Device" prop="linkedId">
						<a-select
							v-model="device.linkedId"
							size="large"
						>
							<template v-for="(dev, devI) in touchscreens">
								<a-select-option :value="dev.id" :key="dev+devI">
									{{ dev.name }}
								</a-select-option>
							</template>
						</a-select>
					</a-form-model-item>
					<div style="font-size: 12px; color: #9ea0a5">
						Select a digital signage
					</div>
				</template>
				<template v-else>
					<div class="mb-3" style="font-weight: bold;">Siteplan Table Settings</div>
					<a-form-model-item label="Hide Hold?">
						<a-switch v-model="siteplanSettings.hidehold" size="large" />
					</a-form-model-item>
					<a-form-model-item label="Hide Available?">
						<a-switch v-model="siteplanSettings.hideavail" size="large" />
					</a-form-model-item>
					<a-form-model-item label="Hide Color?">
						<ColorPicker v-model="siteplanSettings.hidecolor" :defaults="['#ffffff', '#000000', '#ff0000']" />
					</a-form-model-item>
					<a-form-model-item label="Show Tags">
						<a-select
							mode="multiple"
							v-model="siteplanSettings.showtags"
							style="width: 100%"
							size="large"
						>
							<a-select-option v-for="(tag,tagI) in tags" :key="tag+tagI" :value="tag.id">
								{{tag.name}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</template>
			</a-form-model>
        </div>

        <div style="text-align: right">
            <a-button
                size="large"
                @click="onClose"
                class="mt-4 mr-3"
                style="width: 125px"
                >CANCEL</a-button
            >
            <a-button size="large" @click="onSubmit" type="primary" icon="plus"
                >ADD DEVICE</a-button
            >
        </div>
    </a-modal>
</template>

<script>
import BHLoading from "bh-mod/components/common/Loading";
import ColorPicker from "@/components/common/ColorPicker";

export default {
    components: {
        BHLoading,ColorPicker
    },
    data() {
        return {
            device: {},
			siteplanSettings:{},
            rules: {
                code: [
                    {
                        required: true,
                        message:
                            "Please enter the device ID you want to connect",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "Please enter the device name",
                        trigger: "blur",
                    },
                ],
				type: [
                    {
                        required: true,
                        message: "Please select the device type",
                        trigger: "blur",
                    },
                ],
                // linkedId: [
                //     {
                //         required: true,
                //         message: "Please select a digital signage",
                //         trigger: "blur",
                //     },
                // ],
            },
			tags:[],
            loading: false,
        };
    },
    watch: {
        deviceModal: {
            handler(val) {
                if (val.visible) {
                    this.device = {
                        code: "",
                        name: "",
						type: "touch",
                        linkedId: "",
                        url: "",
                    };
					this.siteplanSettings = {
						showtags:[],
						hidehold:false,
						hideavail:false,
						hidecolor:"#bcaea5"
					}
					this.loading = true
					this.$api.get(`/tags/:instance?type=lots`).then(({data}) => {
						console.log('TAGS', data)
						this.tags = data
						this.loading = false
					}).catch(err => {
						this.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            },
            deep: true,
        },
    },
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        deviceModal() {
            return this.$store.state.deviceModal;
        },
        touchscreens() {
            return this.$store.state.appData.touchscreens;
        },
    },
    methods: {
		selectType(e) {
			console.log('Select Type: ', e)
		},
        onClose() {
            this.$store.commit("CLOSE_ADD_DEVICE");
            this.$refs["ruleForm"].resetFields();
        },
        onSubmit() {
			console.log('INSTANCE', this.instance)
			if (this.device.type == 'touch'){
				if (this.device.linkedId == ""){
					this.$message.error('Please select a digital signage')
					return
				}
			}
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    this.loading = true;
                    let sendObj = JSON.parse(JSON.stringify(this.device));
					if (sendObj.type == 'touch'){
						let find = this.touchscreens.find(
							(x) => x.id == sendObj.linkedId
						);
						sendObj.url = `https://${find.slug}.bildhive.${
							this.$tld.includes("dev") ||
							this.$tld.includes("localhost")
								? "org"
								: "site"
						}`;
					} else if (sendObj.type == 'siteplan'){
						let {showtags, hidehold, hideavail, hidecolor} = this.siteplanSettings

						if (hidehold){
							hidehold = '?hidehold=true'
						} else {
							hidehold = '?hidehold=false'
						}

						if (hideavail){
							hideavail = '&hideavail=true'
						} else {
							hideavail = '&hideavail=false'
						}

						if (hidecolor != ''){
							hidecolor = `&hidecolor=${hidecolor.replace('#','')}`
						}

						if (showtags.length > 0){
							showtags = `&showtags=${showtags.join()}`
						} else {
							showtags = ''
						}

						sendObj.url = `https://sptable.bildhive.${this.$tld.includes("dev") || this.$tld.includes("localhost") ? 'dev' : 'com'}/${this.instance.id}${hidehold}${hideavail}${hidecolor}${showtags}`
					}
                    this.$api
                        .post(`/devices/:instance`, sendObj)
                        .then(({ data }) => {
                            this.$store.commit("NEW_DEVICE", data);
                            this.onClose();
                        })
						.catch((error) => {
                            console.error(
                                "Error while adding new device",
                                error.response &&
                                    error.response.data &&
                                    error.response.data.message
                            );
                        })
                        .finally(() => (this.loading = false));
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
    },
    created() {},
    mounted() {},
};
</script>

<style lang="scss">
.domainForm .ant-form-item-with-help {
    margin-bottom: 0;
}
.domainForm .ant-form-item:not(.ant-form-item-with-help) {
    margin-bottom: 19px;
}
</style>

<style lang="scss" scoped>
</style>
