<template>
    <a-modal
        :visible="restartDevice.visible"
        @cancel="onClose"
        @ok="onClose"
        :footer="null"
        :bodyStyle="{ padding: '30px' }"
        centered
    >
        <BHLoading :show="loading" />
        <h5 class="mb-5">Restart your device</h5>

        <div class="w-full">
            <div class="mb-3" style="color: black">
                Please make sure device is turned on and connected to the WiFi
                network.
            </div>
        </div>

        <div class="mt-5 dF jE">
            <a-button
                size="large"
                @click="onClose"
                class="mr-3"
                style="width: 125px"
                >CANCEL</a-button
            >
            <a-button
                size="large"
                @click="onSubmit"
                style="width: 125px"
                type="primary"
                >OK</a-button
            >
        </div>
    </a-modal>
</template>

<script>
import BHLoading from "bh-mod/components/common/Loading";

export default {
    components: {
        BHLoading,
    },
    data() {
        return {
            device: {},
            loading: false,
        };
    },
    watch: {
        restartDevice: {
            handler(val) {
                if (val.visible) {
                    this.device = JSON.parse(JSON.stringify(val.device));
                }
            },
            deep: true,
        },
    },
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        restartDevice() {
            return this.$store.state.restartDevice;
        },
    },
    methods: {
        onClose() {
            this.$store.commit("CLOSE_RESTART_DEVICE");
        },
        onSubmit() {
            this.loading = true;
            this.$api
                .put(`/devices/:instance/${this.device.id}/reboot`)
                .then(({ data }) => {
                    this.$message.success("Device successfully restarted!");
                    this.onClose();
                })
                .catch((error) => {
                    console.error(
                        "Error while restarting device",
                        error.response &&
                            error.response.data &&
                            error.response.data.message
                    );
                })
                .finally(() => (this.loading = false));
        },
    },
    created() {},
    mounted() {},
};
</script>

<style lang="scss">
.domainForm .ant-form-item-with-help {
    margin-bottom: 0;
}
.domainForm .ant-form-item:not(.ant-form-item-with-help) {
    margin-bottom: 19px;
}
</style>

<style lang="scss" scoped>
</style>
