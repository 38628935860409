<template>
    <a-modal
        :visible="domainModal.visible"
        @cancel="onClose"
        @ok="onClose"
        :footer="null"
        :bodyStyle="{ padding: '30px' }"
        centered
    >
        <BHLoading :show="loading" />

        <h5 v-if="step === 0" class="mb-4">Add a domain to your project</h5>
        <h5 v-else class="mb-4">
            Settings for {{ projectName(domain.project) }}
        </h5>

        <!-- Step One -->
        <template v-if="step == 0">
            <div>
                <a-form-model
                    class="domainForm"
                    ref="ruleForm"
                    :model="domain"
                    :rules="rules"
                >
                    <a-form-model-item label="Domain" prop="url">
                        <a-input
                            v-model="domain.url"
                            placeholder="e.g. example.com"
                            size="large"
                        />
                    </a-form-model-item>
                    <div class="mb-4" style="font-size: 12px; color: #9ea0a5">
                        Enter the domain you want to connect
                    </div>

                    <a-form-model-item label="Assign Domain" prop="project">
                        <a-select v-model="domain.project" size="large">
                            <template v-for="(proj, projI) in filteredProjects">
                                <a-select-option
                                    :value="proj.id"
                                    :key="proj + projI"
                                >
                                    {{ proj.name }}
                                </a-select-option>
                            </template>
                        </a-select>
                    </a-form-model-item>
                    <div style="font-size: 12px; color: #9ea0a5">
                        Select a web project
                    </div>
                </a-form-model>
            </div>
        </template>

        <!-- Step Two -->
        <template v-else>
            <div class="w-full">
                <div class="mb-3" style="color: black">Domain</div>
                <div class="w-full dF" style="justify-content: space-between">
                    <div>{{ domain.url }}</div>
                    <div @click="step--" style="color: orange; cursor: pointer">
                        Edit
                    </div>
                </div>

                <div class="mt-5 mb-3" style="color: black">
                    Connecting Your Domain
                </div>
                <div class="mb-3">
                    To connect your domain, you need to log in to your provider
                    account and change your settings. Follow the provider
                    step-by-step instructions to get started.<br /><br />The
                    propagation can take up to 24 hours
                </div>
                <div class="dF jE">
                    <a-button
                        @click="$store.commit('OPEN_INSTRUCTIONS')"
                        class="mb-5"
                        size="large"
                        type="primary"
                        >VIEW INSTRUCTIONS</a-button
                    >
                </div>
            </div>
        </template>

        <div style="text-align: right">
            <a-button
                size="large"
                @click="onClose"
                class="mt-4 mr-3"
                style="width: 125px"
                >CANCEL</a-button
            >
            <a-button
                v-if="step == 0"
                @click="next"
                size="large"
                style="width: 125px"
                type="primary"
                >NEXT</a-button
            >
            <a-button
                v-else
                size="large"
                @click="onSubmit"
                style="width: 125px"
                type="primary"
                >CONNECT</a-button
            >
        </div>
    </a-modal>
</template>

<script>
import BHLoading from "bh-mod/components/common/Loading";

export default {
    components: {
        BHLoading,
    },
    data() {
        return {
            step: 0,
            domain: {
                url: "",
                project: "",
            },
            rules: {
                url: [
                    {
                        required: true,
                        message: "Please enter the domain you want to connect",
                        trigger: "blur",
                    },
                ],
                project: [
                    {
                        required: true,
                        message: "Please select a web project",
                        trigger: "blur",
                    },
                ],
            },
            loading: false,
        };
    },
    watch: {
        domainModal: {
            handler(val) {
                if (val.visible) {
                    this.step = 0;
                    this.domain = {
                        url: "",
                        project: "",
                    };
                }
            },
            deep: true,
        },
    },
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        domainModal() {
            return this.$store.state.domainModal;
        },
        domains() {
            return this.$store.state.appData.domains;
        },
        filteredProjects() {
            let projInDomains = this.domains.map((x) => (x = x.project.id));
            return this.$store.state.appData.projects.filter(
                (x) => !projInDomains.includes(x.id)
            );
        },
        projects() {
            return this.$store.state.appData.projects;
        },
    },
    methods: {
        projectName(proj) {
            let index = this.projects.findIndex((x) => x.id == proj);
            return this.projects[index].name;
        },
        onClose() {
			if(this.$refs["ruleForm"]){
				this.$refs["ruleForm"].resetFields();
			}
            this.$store.commit("CLOSE_ADD_DOMAIN");
        },
        next() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    this.step++;
                } else {
                    return false;
                }
            });
        },
        onSubmit() {
            let sendObj = JSON.parse(JSON.stringify(this.domain));
            this.loading = true;
            this.$api
                .post(`/domains/:instance`, sendObj)
                .then(({ data }) => {
                    this.$store.commit("NEW_DOMAIN", data);
                    this.onClose();
                })
                .catch((error) => {
                    console.error(
                        "Error while creating new domain",
                        error.response &&
                            error.response.data &&
                            error.response.data.message
                    );
                })
                .finally(() => (this.loading = false));
        },
    },
    created() {},
    mounted() {},
};
</script>

<style lang="scss">
.domainForm .ant-form-item-with-help {
    margin-bottom: 0;
}
.domainForm .ant-form-item:not(.ant-form-item-with-help) {
    margin-bottom: 19px;
}
</style>

<style lang="scss" scoped>
</style>
