<template>
    <a-modal
        :visible="renameDevice.visible"
        @cancel="onClose"
        @ok="onClose"
        :footer="null"
        :bodyStyle="{ padding: '30px' }"
        centered
    >
        <BHLoading :show="loading" />
        <h5 class="mb-4">Modify device</h5>

        <div class="w-full">
            <a-form-model
                class="domainForm"
                ref="ruleForm"
                :model="device"
                :rules="rules"
            >
                <a-form-model-item label="Device Name" prop="name">
                    <a-input v-model="device.name" size="large" />
                </a-form-model-item>
            </a-form-model>
        </div>

        <div class="mt-5 dF jE">
            <a-button
                size="large"
                @click="onClose"
                class="mr-3"
                style="width: 125px"
                >CANCEL</a-button
            >
            <a-button
                size="large"
                @click="onSubmit"
                style="width: 125px"
                type="primary"
                >CHANGE</a-button
            >
        </div>
    </a-modal>
</template>

<script>
import BHLoading from "bh-mod/components/common/Loading";

export default {
    components: {
        BHLoading,
    },
    data() {
        return {
            device: {},
            rules: {
                name: [
                    {
                        required: true,
                        message: "Please enter the device name",
                        trigger: "blur",
                    },
                ],
            },
            loading: false,
        };
    },
    watch: {
        renameDevice: {
            handler(val) {
                if (val.visible) {
                    this.device = JSON.parse(JSON.stringify(val.device));
                }
            },
            deep: true,
        },
    },
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        renameDevice() {
            return this.$store.state.renameDevice;
        },
    },
    methods: {
        onClose() {
            this.$store.commit("CLOSE_RENAME_DEVICE");
        },
        onSubmit() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    this.loading = true;
                    let sendObj = JSON.parse(JSON.stringify(this.device));
                    this.$api
                        .put(`/devices/:instance/${sendObj.id}`, sendObj)
                        .then(({ data }) => {
                            this.$store.commit("UPDATE_DEVICE", data);
                            this.onClose();
                        })
                        .catch((error) => {
                            console.error(
                                "Error while renaming device",
                                error.response &&
                                    error.response.data &&
                                    error.response.data.message
                            );
                        })
                        .finally(() => (this.loading = false));
                } else {
                    return false;
                }
            });
        },
    },
    created() {},
    mounted() {},
};
</script>

<style lang="scss">
.domainForm .ant-form-item-with-help {
    margin-bottom: 0;
}
.domainForm .ant-form-item:not(.ant-form-item-with-help) {
    margin-bottom: 19px;
}
</style>

<style lang="scss" scoped>
</style>
