<template>
    <a-modal
        :visible="connectDomain.visible"
        @cancel="onClose"
        @ok="onClose"
        :footer="null"
        :bodyStyle="{ padding: '30px' }"
        centered
    >
        <BHLoading :show="loading" />
        <h5 class="mb-5">Connecting your domain</h5>

        <div class="w-full">
            <div class="mb-4" style="color: black">
                A and CNAME entered incorrectly
            </div>
            <div>
                Check that you've entered the required values, and verify your
                connection again
            </div>

            <div class="dF mt-5 mb-4 aC">
                <div style="color: black">A RECORD (@)</div>
            </div>
            <div class="mb-3">
                <div class="dF aC">
                    Current IP address:&nbsp;
                    <div
                        v-if="domain.verifiedData && domain.verifiedData.length"
                        style="color: #f7685b"
                    >
                        {{ domain.verifiedData[0] }}
                    </div>
                </div>
                <div class="dF aC">
                    Required IP address:&nbsp;
                    <div style="color: black">{{ domain.contentServer }}</div>
                </div>
            </div>
            <hr />
            <div>
                Follow the
                <span
                    @click="$store.commit('OPEN_INSTRUCTIONS')"
                    style="color: var(--orange); cursor: pointer"
                    >step-by-step instructions</span
                >
                and verify your connection again.
            </div>
        </div>

        <div class="mt-5 dF jE">
            <a-button @click="onSubmit" size="large" type="primary"
                >VERIFY AGAIN</a-button
            >
        </div>
    </a-modal>
</template>

<script>
import BHLoading from "bh-mod/components/common/Loading";

export default {
    components: {
        BHLoading,
    },
    data() {
        return {
            domain: {},
            loading: false,
        };
    },
    watch: {
        connectDomain: {
            handler(val) {
                if (val.visible) {
                    this.domain = JSON.parse(JSON.stringify(val.domain));
                }
            },
            deep: true,
        },
    },
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        connectDomain() {
            return this.$store.state.connectDomain;
        },
    },
    methods: {
        onClose() {
            this.$store.commit("CLOSE_CONNECT_DOMAIN");
        },
        onSubmit() {
            this.loading = true;
            this.$api
                .get(`/domains/:instance/${this.domain.id}`)
                .then(({ data }) => {
                    this.$store.commit("UPDATE_DOMAINS", data);
                    this.onClose();
                })
                .catch((error) => {
                    console.error(
                        "Error while verifying domain",
                        error.response &&
                            error.response.data &&
                            error.response.data.message
                    );
                })
                .finally(() => (this.loading = false));
        },
    },
    created() {},
    mounted() {},
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
